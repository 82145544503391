<template>
  <!-- You have to check for HTTP response header X-Frame-Option of those sites.
  if its value is "DENY or SAMEORIGIN", then you can not load those website in the iframes.
  DENY = No one can load the website in iframe. Even the same domain page wont be able to load.
  SAMEORIGIN = only a page which is in same domain can load this website in iframe. -->

  <div class="px-2 my-3">
    <div class="input-group mb-3 iframe__input--url">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-default"
          >Iframe URL</span
        >
      </div>
      <input
        type="text"
        class="form-control"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-default"
        placeholder="Enter Iframe URL here"
        ref="iframeSrc"
        v-on:keyup.enter="fetchWebsite()"
      />
      <button type="button" class="btn btn-primary" @click="fetchWebsite()">
        Fetch
      </button>
    </div>
    <iframe
      :src="iframeSourceUrl"
      frameborder="0"
      width="100%"
      height="800"
      allow="camera; microphone; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'IframeElement',

  data() {
    return {
      iframeSourceUrl: 'https://www.youtube.com/embed/QH2-TGUlwu4',
    };
  },

  methods: {
    reformatUrl(url) {
      let formattedUrl = url;

      // check if the url contains localhost
      if (
        url.includes('localhost')
        && (!url.includes('http') || url.includes('https'))
      ) {
        // if yes, then add http to the url
        formattedUrl = `http://${url}`;
      } else if (
        !url.includes('localhost')
        && (!url.includes('http') || !url.includes('https'))
      ) {
        if (url.includes('www')) {
          formattedUrl = `https://${url}`;
        } else {
          formattedUrl = `https://www.${url}`;
        }
      }
      return formattedUrl;
    },

    fetchWebsite() {
      if (this.$refs.iframeSrc.value && this.$refs.iframeSrc.value.length > 0) {
        // format the url
        const formattedUrl = this.reformatUrl(this.$refs.iframeSrc.value);
        this.$refs.iframeSrc.value = formattedUrl;
        this.iframeSourceUrl = formattedUrl;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-maxmin {
  opacity: 0.8;
  transition: all ease-out 200ms;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: all ease-out 200ms;
  }
}
</style>
